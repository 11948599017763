const ugcClickedEventName = "User Generated Content Clicked";
const ugcVideoPlayedEvent = "User Generated Content Video Playback Started";

const createUgcClickedEvent = (id, position, url, platform, imageUrl) => {
  return {
    event: ugcClickedEventName,
    properties: {
      pixlee_id: id,
      position,
      url,
      platform,
      imageUrl,
    },
  };
};

const createUgcVideoPlayedEvent = (thumbnailUrl, sourceUrl) => {
  return {
    event: ugcVideoPlayedEvent,
    properties: {
      imageUrl: thumbnailUrl,
      videoUrl: sourceUrl,
    },
  };
};

export { createUgcClickedEvent, createUgcVideoPlayedEvent };
